.VideoSection {
  max-width: 100%;
  background-image: url("../assets/video-section-bg.jpg");
  background-position: center;
}

.VideoContainer {
  position: relative;
  min-height: 300px;
  max-height: 450px;
  max-width: 1095px;
  overflow: hidden;
  margin: 0 auto;
}

.VideoControl {
  position: relative;
  max-height: 32px;
  max-width: 1095px;
  overflow: hidden;
  margin: -32px auto 0 auto;
  text-align: right;
}

.VideoControl svg{
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.VideoControl .VideoMute,
.VideoControl .VideoUnmute{
  height: 32px;
  width: 32px;
  position: relative;
  margin-right: 0;
  margin-left: auto;
}

/* Style the video: 100% width and height to cover the entire window */
.Video {
  width: 100%;
  height: 100%;
  margin-top: -65px;
}

@media only screen and (max-width: 1112px) {
  .VideoSection {
    background-size: cover;
  }
  .VideoContainer {
    min-height: 300px;
    max-height: 300px;
    max-width: 767px;
  }
  .VideoControl {
    max-width: 767px;
    max-height: 28px;
    margin: -28px auto 0 auto;
  }  
  .VideoControl svg,
  .VideoControl #Mute,
  .VideoControl #UnMute{
    height: 28px;
    width: 28px;
  }
}

@media only screen and (max-width: 768px) {
  .VideoSection {
    padding-left: 0;
    padding-right: 0;
  }
  .VideoContainer {
    min-height: 225px;
    max-height: 225px;
    max-width: 500px;
  }
  .Video {
    margin-top: -35px;
  }
  .VideoControl {
    max-width: 500px;
    max-height: 24px;
    margin: -24px auto 0 auto;
  }  
  .VideoControl svg,
  .VideoControl #Mute,
  .VideoControl #UnMute{
    height: 24px;
    width: 24px;
  }
}

@media only screen and (max-width: 500px) {
  .VideoContainer {
    min-height: 170px;
    max-height: 170px;
    max-width: 100%;
  }
  .Video {
    margin-top: -20px;
  }
}