
main .cta {
    padding-bottom: 50px;
    padding-top: 50px;
    text-align: center;
}
  
main .cta h3 {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 34px;
}
  
main .cta p {
    font-size: 18px;
    font-weight: 200;
    line-height: 28px;
}
  
main .cta button {
    border: none;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 24px;
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 12px;
    line-height: 48px;
    text-transform: uppercase;
    padding-left: 24px;
    padding-right: 24px;
}
  
@media only screen and (max-width: 1112px) {
    main .cta h3 {
        font-size: 22px;
        line-height: 32px;
    }    
    main .cta p {
        font-size: 16px;
        line-height: 26px;
    }    
    main .cta button {
        font-size: 22px;
        line-height: 46px;
    }
}
  
@media only screen and (max-width: 767px) {
    main .cta h3 {
        font-size: 16px;
        line-height: 28px;
    }    
    main .cta p {
        font-size: 12px;
        line-height: 20px;
    }    
    main .cta button {
        font-size: 18px;
    }
}
  