.container {
  background-color: #257a8e;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-top: -65px;
  text-align: center;
  width: 750px;
}

.countdownbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 20px;
  padding-top:10px;
}

.countdownbox div{
  padding-left: 8px;
  padding-right: 8px;
}

.countdownbox div.textpresenter{
  padding-right: 18px;
}

.textpresenter {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.divider {
  font-size: 40px;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  margin-top: -24px;
}

.count {
  font-size: 72px;
  font-weight: 700;
  line-height: 78px;
  display: block;
}

@media only screen and (max-width: 1112px) {
  .container {
    margin-top: -55px;
    width: 599px;
  }
  .countdownbox {
    font-size: 14px;
  }
  .textpresenter{
    font-size: 15px;
    line-height: 20px;
  }
  .count {
    font-size: 52px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    margin-top: -40px;
    width: 468px;
  }
  .textpresenter{
    font-size: 12px;
    line-height: 16px;
  }
  .divider {
    font-size: 25px;
    margin-top: -16px;
  }
  .count {
    font-size: 28px;
    line-height: 33px;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    margin-top: 0;
    width: 100%;
  }
  .countdownbox {
    padding-bottom: 10px;
    padding-top: 5px;
  }
  .countdownbox div{
    padding-left: 5px;
    padding-right: 5px;
  }
}