main .leaderboardheader {
    align-items: center;
    background-color: #3f778b;
    border-bottom: 1px solid #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
}
  
main .leaderboardheader h1 {
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    line-height: 32px;
    padding-left: 12px;
    padding-right: 12px;
    text-transform: uppercase;
}
  
main .leaderboardheader span {
    color: #ffcd00;
    border-right: 1px solid #b5b5b5;
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    line-height: 31px;
    width: auto;
    padding-right: 12px;
    text-transform: uppercase;
}
  
main .leaderboardheader p {
    font-size: 14px;
    display: inline-block;
    line-height: 31px;
    width: auto;
}
  
main .leaderboard.top .tableheader .rank .smdisplay{
    display: none;
}
  
main .leaderboard .tableheader {
    align-items: center;
    background-color: #336274;
    display: flex;
    justify-content: space-between;
    padding: 12px
}
  
main .leaderboard .tableheader .tablecell  {
    align-items: center;
    color: #ffcd00;
    font-size: 18px;
    font-weight: 700;
    flex-basis: 33%;
    flex-grow: 1;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
}
  
main .leaderboard .tableheader .tablecell.username  {
    border-left: 1px solid #b5b5b5;
    border-right: 1px solid #b5b5b5;
}
  
main .leaderboard .tablerow {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 12px
}
  
main .leaderboard .tablerow.odd {
    background-color: #5aa5b8;
}
  
main .leaderboard .tablerow.even{
    background-color: #3d8697;
}
  
main .leaderboard .tablerow .tablecell  {
    align-items: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    flex-basis: 33%;
    flex-grow: 1;
    line-height: 20px;
    text-align: center;
}
  
main .leaderboard .tablerow.top3 .tablecell{
    color: #ffcd00;
    font-weight: 700;
}
  
main .leaderboard .tablerow .tablecell.rank {
    font-weight: 700;
}
  
main .leaderboard .tablerow .tablecell.username {
    display: flex;
    justify-content: center;
}
  
main .leaderboard .tablerow .tablecell.username span{
    display: inline-block;
    min-width: 180px;
    padding-left: 12px;
}
  
main .leaderboard .tablerow .tablecell.username .flag {
    background-size: 20px;
    background-position: top center;
    background-repeat: no-repeat;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    display: inline-block;
    height: 20px;
    width: 20px;
}
  
main .leaderboard .tablerow .tablecell.username .flag.MYR {
    background-image: url('../assets/flag-myr.jpg');
}
  
main .leaderboard .tablerow .tablecell.username .flag.CNY {
    background-image: url('../assets/flag-cny.jpg');
}
  
main .leaderboard .tablerow .tablecell.username .flag.IDR {
    background-image: url('../assets/flag-idr.jpg');
}
  
main .leaderboard .tablerow .tablecell.username .flag.THB {
    background-image: url('../assets/flag-thb.jpg');
}
  
main .leaderboard .tablerow .tablecell.username .flag.VND {
    background-image: url('../assets/flag-vnd.jpg');
}
  
main .leaderboard.below {
    display: flex;
    justify-content: space-between;
}
  
main .leaderboard.below .col {
    flex-grow: 1;
    margin-top: 25px;
    max-width: 537px;
}
  
main .leaderboard.below .tablerow .tablecell.username span{
    min-width: 150px;
}
  
main .leaderboard.below .tableheader .tablecell.rank,
main .leaderboard.below .tablerow .tablecell.rank {
    max-width: 124px;
    width: 100%;
}
  
main .leaderboard.below .tableheader .tablecell.ticket,
main .leaderboard.below .tablerow .tablecell.ticket {
    max-width: 150px;
    width: 100%;
}
  
@media only screen and (max-width: 1112px) {
    main .leaderboardheader {
        padding: 12px 16px;
    }
    main .leaderboardheader span,
    main .leaderboardheader h1 {
        font-size: 16px;
        line-height: 28px;
    }
    main .leaderboardheader p {
        font-size: 12px;
        line-height: 28px;
    }
    main .leaderboard .tableheader .tablecell {
        font-size: 16px;
        line-height: 30px;
    }
    main .leaderboard .tablerow .tablecell {
        font-size: 12px;
        line-height: 18px;
    }
    main .leaderboard.below .col {
        margin-top: 20px;
    }
    main .leaderboard.below .col.left {
        margin-right: 4px;
    }
    main .leaderboard.below .col.right {
        margin-left: 4px;
    }
}
  
@media only screen and (max-width: 767px) {
    main .leaderboard.below {
        flex-direction: column;
    }
    main .leaderboard.below .col,
    main .leaderboard.below .col.left,
    main .leaderboard.below .col.right {
        margin: 0;
    }
    main .leaderboard.below .col.right {
        display: none;
    }
    main .leaderboardheader p,
    main .leaderboard.below .tableheader,
    main .leaderboard.top .tableheader .rank .mddisplay{
        display: none;
    }
    main .leaderboardheader .col.left {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    main .leaderboardheader span {
        border: none;
        padding: 0;
    }
    main .leaderboard .tableheader {
        padding: 10px;
    }
    main .leaderboard .tableheader .tablecell {
        font-size: 12px;
    }
    main .leaderboard.top .tableheader .rank .smdisplay{
        display: inline-block;
    }
    main .leaderboard.top .tablerow .tablecell.username span,
    main .leaderboard.below .tablerow .tablecell.username span {
        min-width: 160px;
        text-align: left;
    }
    main .leaderboard .tablerow .tablecell.rank,
    main .leaderboard .tableheader .tablecell.rank  {
        flex-basis: 20%;
        flex-grow: 0;
    }
    main .leaderboard .tablerow .tablecell.username,
    main .leaderboard .tableheader .tablecell.username,
    main .leaderboard .tablerow .tablecell.ticket,
    main .leaderboard .tableheader .tablecell.ticket {
        flex-basis: 40%;
        flex-grow: 0;
    }
    main .leaderboard.below .col,
    main .leaderboard.below .tablerow .tablecell.ticket {
        max-width: 100%;
    }
    main .leaderboard.below .col.left .tablerow.odd {
        background-color: #5aa5b8;
    }
    main .leaderboard.below .col.left .tablerow.even{
        background-color: #3d8697;
    }
}

/** video replay button **/
.leaderboardheader .col.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.leaderboardheader .col.right p{
    font-size: 14px;
    line-height: 20px;
}

figure {
  margin:0; padding:0;
  height: auto;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
}

figure .playbtn{
    background: red;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 12px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    padding-right: 15px;
    cursor: pointer;
}

figure button[name="play"] {
  width: 42px;
  height:42px;
  background: red;
  border: none;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  margin: auto;
  cursor: pointer;
}
figure button[name="play"]:focus {
  outline: 0;
  border: 1px solid hsl(210, 58%, 69%);
  box-shadow: 0 0 0 3px hsla(210, 76%, 57%, 0.5);
}

figure button[name="play"]::after {
  content: '';
  display: inline-block;
  position: relative;
  top: 1px;
  left: 3px;
  border-style: solid;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent white;
}

.vidContainer {
    width: 750px;
    height: 440px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}