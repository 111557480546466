* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #1a2332;
  color: #ffffff;
  font-family: 'Open Sans', 'OpenSans', Tahoma, Helvetica, sans-serif;
}

section {
  margin: 0 auto;
  max-width: 1366px;
}

button {
  background-color: #ffcd00;
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.blankspace {
  display: block;
  width: 100%;
  min-height: 250px;
}

@media only screen and (max-width: 1366px) {
  section {
      padding-left: 8px;
      padding-right: 8px;
  }
}

#popup,
#popupVideo {
  position: absolute;
  top: -50%; /*Put it on the very top*/
  left: 50%;
  background: #257a8e;
  width: 610px;
  height: 422px;
  margin-left: -305px; /*Half the value of width to center div*/
  margin-top: -211px; /*Half the value of height to center div*/
  z-index: 200;
  visibility: hidden;
  opacity: 0;
  z-index: -1; /*hide it in the back of the page*/
  transition: all .5s ease-in-out; /*make it smooth*/
}

#popupVideo {
  width: 888px;
  height: 600px;
  margin-left: -444px; /*Half the value of width to center div*/
  margin-top: -300px; /*Half the value of height to center div*/
}

#overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #000000;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: -1; /*hide it in the back of the page*/
}

/*Now show it*/
#popup.show,
#popupVideo.show {
  visibility: visible;
  z-index: 200;
  opacity: 1;
  top: 50%; /*slide it down smoothly*/
  position: fixed;
}
#overlay.show{
  opacity: .5;
  visibility: visible;
  z-index: 100;
  position: fixed;
}
#popupclose,
#popupVideoClose {
  cursor: pointer;
  float: right;
  height: 25px;
  width: 25px;
  padding: 10px;
}

.mdiv {
  height: 25px;
  width: 2px;
  margin-left: 12px;
  background-color: #ffffff;
  transform: rotate(45deg);
  Z-index: 1;
}

.md {
  height: 25px;
  width: 2px;
  background-color: #ffffff;
  transform: rotate(90deg);
  Z-index: 2;
}

.popupcontent {
  width: 448px;
  margin: 88px auto 0 auto;
}

#popupVideo .popupcontent {
  width: 750px;
  margin: 48px auto 0 auto;
}

.popupcontent h4 {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 8px;
}

.popupcontent p {
  font-size: 12px;
  line-height: 18px;
}

.popupcontent .resultbox {
  display: flex;
  flex-direction: row;
}

.popupcontent .resultbox div {
  flex-grow: 1;
}

.popupcontent .formbox {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.popupcontent .formbox label {
  font-size: 14px;
  margin-top: 12px;
}

.popupcontent .formbox input {
  border: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 12px;
  line-height: 40px;
  padding-left: 8px;
}

.popupcontent .formbox button {
  border: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;
  line-height: 40px;
  text-transform: uppercase;
}

.popupcontent .searchsuccess h4 strong {
  color: #ffcd00;
}

.popupcontent .searchsuccess p {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.popupcontent .searchsuccess span {
  color: #ffcd00;
  display: block;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.popupcontent .searchsuccess button {
  border: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;
  line-height: 36px;
  text-transform: uppercase;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}

@media only screen and (max-width: 1112px) {
  .popupcontent .searchsuccess button {
      font-size: 22px;
      line-height: 46px;
  }
}

@media only screen and (max-width: 767px) {
  #popup {
      width: 360px;
      height: 380px;
      margin-left: -180px; /*Half the value of width to center div*/
      margin-top: -190px; /*Half the value of height to center div*/
  }
  .popupcontent {
      width: 327px;
      margin: 75px auto 0 auto;
  } 
  .popupcontent .searchsuccess button {
      font-size: 18px;
  }
}

main {
  background-color: #0790b2;
  background-image: url('./assets/mainbg_lg.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 1920px;
  padding-top: 80px;
}

main .container {
  max-width: 1095px;
  margin: 0 auto;
}


@media only screen and (max-width: 1112px) {
  main {
      background-size: 1500px;
  }
}

@media only screen and (max-width: 767px) {
  main {
      background-size: 1040px;
  }
}

main .tnc {
  padding-bottom: 50px;
}

main .tnc h2 {
  background-image: url('./assets/tnc-icon.png');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding-left: 30px;
}

main .tnc .accordion {
  background-color: #40778c;
  color: #ffffff;
  cursor: pointer;
  border: none;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  transition: 0.4s;
  margin-top: 12px;
  outline: none;
  padding: 12px;
  width: 100%;
}

main .tnc .active, 
main .tnc .accordion:hover {
  background-color: #3d8697;
}

main .tnc .accordion:after {
  content: '\002B';
  color: #ffffff;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

main .tnc .active:after {
  content: "\2212";
}

main .tnc .panel {
  padding: 0 18px;
  background-color: #5aa5b8;
  font-size: 12px;
  line-height: 22px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

main .tnc .panel .content {
  padding-top: 12px;
  padding-bottom: 24px;
}

main .tnc .panel .content .row {
  display: flex;
  justify-content: space-between;
}

main .tnc .panel .content .row ol {
  max-width: 512px;
  flex-grow: 1;
}

main .tnc .panel .content .row ol li{
  margin-left: 12px;
}

main .tnc .panel .content table {
  border-collapse: collapse;
  width: 100%;
}

main .tnc .panel .content thead {
  background-color: #336274;
  line-height: 32px;
}

main .tnc .panel .content td {
  padding: 2px;
  text-align: center;
}

main .tnc .panel .content .prizetable td {
  width: 33%;
}

main .tnc .panel .content tbody tr:nth-child(odd) {
  background-color: #3d8697;
}

main .tnc .panel .content tbody tr:nth-child(even) {
  background-color: #5aa5b8;
  border-bottom: 1px solid #3d8697;
}

@media only screen and (max-width: 1112px) {
  main .tnc .panel .content .row {
      flex-direction: column;
  }
  main .tnc .panel .content .row ol {
      max-width: 100%;
  }
}

footer {
  background-color: #000000;
}

footer .container.gamesupplier {
  border-bottom: 1px dashed #B5B5B5;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top: 12px;
  padding-bottom: 12px;
}

footer .container.gamesupplier .vendor {
  margin: 12px 24px;
  min-height: 40px;
}

footer .container.sponsorandlicense {
  align-items: center;
  display: flex;
  padding: 24px 0;
  justify-content: space-between;
}

footer .container .sponsorship {
  display: flex;
  width: 90%;
}

footer .container .sponsorship .sponsor {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

footer .container .sponsorship .sponsor.laliga .imagesponsor.horizontal{
  display: none;
}

footer .container .sponsorship .sponsor .sponsordetails {
  color: #b5b5b5;
  font-size: 12px;
  line-height: 18px;
  margin-left: 16px;
  text-transform: uppercase;
}

footer .container .sponsorship .sponsor.laliga .sponsordetails span{
  color: #ffffff;
}

footer .container .licenses {
  display: flex;
  justify-content: flex-end;
  width: 10%;
}

footer .container.copyright .licenses {
  display: none;
}

footer .container .licenses .imageLicense{
  margin-left: 16px;
}

footer .container.copyright {
  color: #b5b5b5;
  display: flex;
  font-size: 12px;
  line-height: 18px;
  justify-content: space-between;
  padding: 24px 0;
}

footer .container.copyright .eclipse {
  text-align: center;
  border: 1px solid #b5b5b5;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  display: inline-block;
  width: 24px;
  height: 22px;
  margin-right: 5px;
  padding-top: 2px;
}

@media only screen and (max-width: 1112px) {
  footer .container.gamesupplier .vendor {
      margin: 10px 22px;
      min-height: 35px;
  }
  footer .container.gamesupplier .vendor .vendorlogo{
      height: 35px;
  }
  footer .container .sponsorship {
      width: 100%;
      flex-wrap: wrap;
  }
  footer .container .sponsorship .sponsor.laliga {
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      margin-bottom: 22px;
      text-align: center;
      width: 100%;
  }
  footer .container .sponsorship .sponsor.laliga .imagesponsor.vertical {
      display: none;
  }
  footer .container .sponsorship .sponsor.laliga .imagesponsor.horizontal {
      display: block;
      margin-bottom: 12px;
  }    
  footer .container .sponsorship .sponsor.laliga .sponsordetails {
      margin: 0;
  }
  footer .container.copyright {
      flex-direction: column;
      align-items: center;
  }
  footer .container.copyright .text-left {
      align-items: center;
      align-self: flex-start;
      border-bottom: 1px dashed #b5b5b5;
      border-top: 1px dashed #b5b5b5;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 18px 0;
      width: 100%;
  }
  footer .container.sponsorandlicense .licenses {
      display: none;
  }
  footer .container.copyright .licenses {
      display: flex;
  }
}

@media only screen and (max-width: 767px) {
  footer .container.gamesupplier .vendor {
      margin: 8px 18px;
      min-height: 24px;
  }
  footer .container.gamesupplier .vendor .vendorlogo{
      height: 24px;
  }
  footer .container.sponsorandlicense {
      padding: 24px 0 0 0;
  }
  footer .container .sponsorship .sponsor:not(.laliga) .sponsordetails strong,
  footer .container .sponsorship .sponsor:not(.laliga) .sponsordetails br {
      display: none;
  }
  footer .container .sponsorship .sponsor:not(.laliga) {
      display: flex;
      flex-direction: column;
  }
  footer .container .sponsorship .sponsor .sponsordetails {
      margin: 0;
  }
}

#popupVideo .react-tabs__tab--selected {
  background: #ffcd00;
  border-color: #ffcd00;
  color: #000;
  font-weight: 600;
  border-radius: 2px 2px 0 0;
}

#popupVideo .react-tabs__tab-list {
  border-bottom: 1px solid #ffcd00;
  margin: 0 0 8px;
}

#popupVideo .react-tabs__tab:hover {
  color: #ffcd00;
}

#popupVideo .react-tabs__tab--selected:hover {
  color: #000;
}