header {
    background-color: #1a2332;
}
  
header .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-height: 64px;
    padding-top: 20px;
    padding-bottom: 20px;
}
  
header .container .col.right{
    align-items: center;
    display: flex;
}
  
header .container .imageLogo{
    cursor: pointer;
}
  
header .container button {
    border: none;
    cursor: pointer;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    padding-left: 13px;
    padding-right: 13px;
}
  
header .container .col.right a.view {
    color: #ffcd00;
    border: 1px solid #ffcd00;
    font-size: 12px;
    line-height: 22px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 8px 12px;
    text-decoration: none;
    margin-left: 8px;
    margin-right: 8px;
}


header .container .col.right a.view:hover {
    color: #d3b271;
    border: 1px solid#d3b271;
}

header .container .dropbtn {
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
}

header .container .dropbtn .arrow {
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    margin-left: 8px;
}

header .container .dropbtn .arrow.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
  
header .container .langselect {
    position: relative;
    display: inline-block;
    margin-left: 8px;
}
  
header .container .langselect .flag {
    height: 24px;
    width: 24px;
}
  
header .container .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    right: 0;
    z-index: 1;
    -webkit-border-radius: 2px 0 2px 2px;
    -moz-border-radius: 2px 0 2px 2px;
    border-radius: 2px 0 2px 2px;
}

header .container .dropdown-content a {
    color: black;
    padding: 8px 12px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

header .container .dropbtn span,
header .container .dropdown-content a span{
    margin-left: 8px;
    font-size: 12px;
}
  
header .container .dropdown-content a:hover {
    background-color: #f1f1f1;
}
header .container .langselect:hover .dropdown-content {
    display: block;
}

header .container .langselect:hover .dropbtn {
    background-color: #f1f1f1;
    color: #333333;
}
header .container .langselect:hover .dropbtn .arrow{
    border: solid #333333;
    border-width: 0 2px 2px 0;
}
  
@media only screen and (max-width: 767px) {
    header .container {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    header .container button {
        font-size: 12px;
        line-height: 32px;
    }
    header .container .imageLogo {
        width: 218px;
    }
}
 
@media only screen and (max-width: 500px) {
    header .container .imageLogo {
        width: 160px;
    }
}